import React from 'react';
import PropTypes from 'prop-types';
import './memberships.scss';
import MembershipHeader from './components/MembershipHeader';
import MembershipPlan from './components/MembershipPlan';
import { getBronzePlan, getGoldPlan, getPlatinumPlan } from '../../../helpers/find-plans.helper';

const Memberships = ({header, bronzeMembership, goldMembership, platinumMembership, plansFromApi}) => {
  const interval = 'month';
  const bronzePlan = getBronzePlan(plansFromApi, interval);
  bronzeMembership.code = 'bronze';
  const goldPlan = getGoldPlan(plansFromApi, interval);
  const platinumPlan = getPlatinumPlan(plansFromApi, interval);

  return (
    <section>
      <MembershipHeader
        title={header.title}
        backgroundImage={header.backgroundImage}
        intro={header.intro}
      />
      <MembershipPlan
        title={bronzeMembership.title}
        benefits={bronzeMembership.benefits}
        plan={bronzePlan}
      />      
      <MembershipPlan
        title={goldMembership.title}
        benefits={goldMembership.benefits}
        plan={goldPlan}
      />
      <MembershipPlan
        title={platinumMembership.title}
        benefits={platinumMembership.benefits}
        plan={platinumPlan}
      />
    </section>
  );
};

Memberships.propTypes = {
  header: PropTypes.object,
  bronzeMembership: PropTypes.object,
  goldMembership: PropTypes.object,
  platinumMembership: PropTypes.object,
  plansFromApi: PropTypes.array,
};

export default Memberships;
